import axios from "axios";

const baseURL = (() => {
  if (process.env.RAILS_ENV === 'test') {
    return 'http://api.lvh.me:53000';
  } else if (process.env.RAILS_ENV === 'production') {
    return `https://${process.env.API_SUBDOMAIN}${process.env.DOKSPOT_DOMAIN}`;
  } else {
    return 'http://api.lvh.me:3000';
  }
})();

const api = axios.create({
  baseURL: baseURL,
});

export default api;
