import React, { useState, useEffect, useCallback } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import axios from '../connect/helpers/Api';
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Cookies } from 'react-cookie'
import { withTranslation, Trans } from 'react-i18next'

import { TypeaheadAdapter, AsyncTypeaheadAdapter } from './TypeaheadAdapter'
import mixpanel from 'mixpanel-browser'
import { setReference } from 'redux/actions/reference'
import { connect } from 'react-redux'
import { camelize } from 'components/shared/utils'
import { SELECTED_COUNTRY_CODE_COOKIE_NAME } from 'components/connect/helpers/Constants'

const propTypes = {
  theme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  handleTestFn: PropTypes.func
}

const HEX_APLHA_80 = 'cc'
const alphasizeColor = (color) => `${color}${HEX_APLHA_80}`
const referencesKey = 'referencesQuery'
const lotsKey = 'lotsQuery'

const StyledButton = styled.button`
  color: ${({ theme }) => theme.layout.button_text_color};
  border-color: ${({ theme }) => theme.layout.button_background_color};
  background-color: ${({ theme }) => theme.layout.button_background_color};

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) =>
    alphasizeColor(theme.layout.button_background_color)};
    color: ${({ theme }) => theme.layout.button_text_color};
    border-color: ${({ theme }) =>
    alphasizeColor(theme.layout.button_background_color)};
  }
`

const FindForm = withRouter(({ theme, history, t }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRef, setSelectedRef] = useState([])
  const [selectedLot, setSelectedLot] = useState([])
  const [refOptions, setRefOptions] = useState([])
  const [lotOptions, setLotOptions] = useState([])
  const [errors, setErrors] = useState({
    request: false,
    reference: false,
    lot: false
  })
  const cookies = new Cookies()
  const countryCode = cookies.get(SELECTED_COUNTRY_CODE_COOKIE_NAME)

  const filterReferencesByCountry = (refs) => {
    if (!theme.country_selection) {
      return refs
    }
    return refs.filter(({ distributionList, distributionType }) => {
      if (distributionType === 'global') {
        return true
      } else if (distributionType === 'allow' && distributionList.includes(countryCode)) {
        return true
      } else if (distributionType === 'block' && !distributionList.includes(countryCode)) {
        return true
      }
      return false
    })
  }

  const handleSearch = useCallback(async (query) => {
    try {
      setIsLoading(true)
      const request = await axios.get(`/themes/${theme.id}/product_references?search=${query}`)
      const refs = filterReferencesByCountry(camelize(request.data.products))
      setRefOptions(refs)
    }
    catch (e) {
      setErrors({ ...errors, request: true })
    }
    finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (selectedRef.length) {
      const { productId, name: ref } = selectedRef[0]
      setErrors({ ...errors, reference: false })

      if (selectedLot.length) {
        const { title: lot } = selectedLot[0]
        setErrors({ ...errors, lot: false })
        history.push(`/products/${productId}?reference=${ref}&lot=${lot}`)
        return
      }
      const { lotNumbers } = selectedRef[0]

      if (lotNumbers.length) {
        setLotOptions(lotNumbers)
        return
      }
      history.push(`/products/${productId}?reference=${ref}`)
      mixpanel.track('FindForm.handleSubmit', selectedRef)
      return
    }
    setLotOptions([])
    setErrors({ ...errors, lot: false })
  }, [selectedRef, selectedLot])

  const handleSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (!selectedRef.length) {
      const refValue = event.target.elements[referencesKey].value
      const option = refOptions.filter(opt => opt.name === refValue)

      if (option.length) {
        setSelectedRef(option)
        return
      }
      setErrors({ ...errors, reference: true })
      return
    }
    const lotValue = event.target.elements[lotsKey].value
    const option = lotOptions.filter(opt => opt.title === lotValue)

    if (option.length) {
      setSelectedLot(option)
      return
    }
    setErrors({ ...errors, lot: true })
  }

  return (
    <div data-testid='find-form' className='find-form'>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId={referencesKey}>
          <Form.Label data-testid='find-form-reference-text'>
            {t('find_form.references_field.label')}
          </Form.Label>
          <InputGroup>
            <Form.Control
              as={AsyncTypeaheadAdapter}
              typeaheadId={referencesKey}
              dataTestId='find-form-references-input'
              typeaheadPlaceholder={t('find_form.references_field.placeholder')}
              theme={theme}
              isLoading={isLoading}
              options={refOptions}
              onSearch={handleSearch}
              selected={selectedRef}
              labelKey='name'
              onChange={setSelectedRef}
              required
            />
            {!lotOptions.length && (
              <InputGroup.Append>
                <StyledButton
                  type='submit'
                  data-testid='find-form-button'
                  className='btn find-form-button'
                  theme={theme}
                >
                  {t('find_form.button')}
                </StyledButton>
              </InputGroup.Append>
            )}
          </InputGroup>
          {errors.reference && (
            <p className='text-danger' data-testid='find-form-reference-error'>
              {t('find_form.errors.reference.required')}
            </p>
          )}
        </Form.Group>
        {!!lotOptions.length && (
          <>
            <Form.Group controlId={lotsKey}>
              <Form.Label data-testid='find-form-lots-text'>
                {t('find_form.lots_field.label')}
              </Form.Label>
              <InputGroup>
                <Form.Control
                  as={TypeaheadAdapter}
                  options={lotOptions}
                  onChange={setSelectedLot}
                  selected={selectedLot}
                  labelKey='title'
                  typeaheadId={lotsKey}
                  dataTestId='find-form-lots-input'
                  typeaheadPlaceholder={t('find_form.lots_field.placeholder')}
                  theme={theme}
                  required
                />
                <InputGroup.Append>
                  <StyledButton
                    type='submit'
                    data-testid='find-form-button'
                    className='btn find-form-button'
                    theme={theme}
                  >
                    {t('find_form.button')}
                  </StyledButton>
                </InputGroup.Append>
              </InputGroup>
              {errors.lot && (
                <p className='text-danger' data-testid='find-form-lots-error'>
                  {t('find_form.errors.lot.required')}
                </p>
              )}
            </Form.Group>
          </>
        )}
      </Form>
    </div>
  )
})

FindForm.propTypes = propTypes

// REVIEW: We're importing this action here but it doesn't seem to be called by
// anything in this component. Is this legacy?
const mapStateToProps = ({ reference }) => ({ reference })

export default connect(mapStateToProps, { setReference })(
  withTranslation()(FindForm)
)
